<script>
export default {
    
}
</script>

<template>
    <div class="card card-height-100">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Top Referrals Pages</h4>
                                        <div class="flex-shrink-0">
                                            <button type="button" class="btn btn-soft-primary btn-sm shadow-none">
                                                Export Report
                                            </button>
                                        </div>
                                    </div>

                                    <div class="card-body">

                                        <div class="row align-items-center">
                                            <div class="col-6">
                                                <h6 class="text-muted text-uppercase fw-semibold text-truncate fs-12 mb-3">Total Referrals Page</h6>
                                                <h4 class="fs- mb-0">725,800</h4>
                                                <p class="mb-0 mt-2 text-muted"><span class="badge badge-soft-success mb-0">
                                                    <i class="ri-arrow-up-line align-middle"></i> 15.72 %
                                                </span> vs. previous month</p>
                                            </div><!-- end col -->
                                            <div class="col-6">
                                                <div class="text-center">
                                                    <img src="@/assets/images/illustrator-1.png" class="img-fluid" alt="">
                                                </div>
                                            </div><!-- end col -->
                                        </div><!-- end row -->
                                        <div class="mt-3 pt-2">
                                            <div class="progress progress-lg rounded-pill">
                                                <div class="progress-bar bg-primary" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-info" role="progressbar" style="width: 18%" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-success" role="progressbar" style="width: 22%" aria-valuenow="22" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-warning" role="progressbar" style="width: 16%" aria-valuenow="16" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div class="progress-bar bg-danger" role="progressbar" style="width: 19%" aria-valuenow="19" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div><!-- end -->

                                        <div class="mt-3 pt-2">
                                            <div class="d-flex mb-2">
                                                <div class="flex-grow-1">
                                                    <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-primary me-2"></i>www.google.com</p>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <p class="mb-0">24.58%</p>
                                                </div>
                                            </div><!-- end -->
                                            <div class="d-flex mb-2">
                                                <div class="flex-grow-1">
                                                    <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-info me-2"></i>www.youtube.com</p>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <p class="mb-0">17.51%</p>
                                                </div>
                                            </div><!-- end -->
                                            <div class="d-flex mb-2">
                                                <div class="flex-grow-1">
                                                    <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-success me-2"></i>www.meta.com</p>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <p class="mb-0">23.05%</p>
                                                </div>
                                            </div><!-- end -->
                                            <div class="d-flex mb-2">
                                                <div class="flex-grow-1">
                                                    <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-warning me-2"></i>www.medium.com</p>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <p class="mb-0">12.22%</p>
                                                </div>
                                            </div><!-- end -->
                                            <div class="d-flex">
                                                <div class="flex-grow-1">
                                                    <p class="text-truncate text-muted fs-14 mb-0"><i class="mdi mdi-circle align-middle text-danger me-2"></i>Other</p>
                                                </div>
                                                <div class="flex-shrink-0">
                                                    <p class="mb-0">17.58%</p>
                                                </div>
                                            </div><!-- end -->
                                        </div><!-- end -->

                                        <div class="mt-2 text-center">
                                            <a href="javascript:void(0);" class="text-muted text-decoration-underline">Show All</a>
                                        </div>

                                    </div><!-- end card body -->
                                </div><!-- end card -->
</template>